import { KeyboardEvent, useEffect, useRef, useState } from "react";
import {
  Avatar, Box, FormControl, FormControlLabel, Grid, IconButton, InputLabel,
  List, ListItem, ListItemIcon, ListItemText, MenuItem, Radio, RadioGroup,
  Select, TextField, Tooltip, Typography
} from "@mui/material";
import { addDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Gender, License, OrderInputProps } from "../_interfaces/OrderInterface";
import "../_styles/Style.css";
import { useFormControls } from "../_helpers/FormValidation";
import { userHasValidInvoiceInfo } from "../_helpers/AccessRoles";
import { useUser } from "../_hooks/useUser";
import browserHistory from "../_helpers/History";
import { LoadingFormButton } from "../_components/LoadingFormButton";
import { DateField } from "../_components/DateField";
import { SLink } from "../_components/SLink";
import { BuyLicenseModal } from "../_components/BuyLicenseModal";

const OrderPage = ({ license: licenseFromMenuClick }: { license: License }): JSX.Element => {

  const { user } = useUser();
  const today = new Date();
  const inTwoWeeksTime = addDays(today, 14);
  const license = user.licenses.find(l => l.label === licenseFromMenuClick.label);
  if (!license) {
    throw new Error('wrong license')
  }

  const initialValues = {
    name: "",
    email: "",
    gender: Gender.female,
    company: "",
    language: license.languages.length > 0 ? license.languages[0] : "swedish",
    endDate: inTwoWeeksTime,
    reminderDate: null,
    license: license.key
  }

  const { 
    handleInputValue,         
    handleInputValidation,
    handleOrderFormSubmit,
    formIsValid,
    errors,
    values,
    setValues
  } = useFormControls(initialValues);

  const requiredFields = ['email', 'name'];
  const productCategory = license.type === '360' ? 'kartläggning' : '';
  const nameRef = useRef<HTMLInputElement>();
  
  const [orderArray, updateOrderArray] = useState<Array<OrderInputProps>>([]);

  // After adding, editing or removing user from list of users, start again at top of form
  const refocusForm = (f?: {(): void}): void => {
    const nameField = nameRef.current;
    if (nameField) {
      // Wait 0.5 sec
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 500);
      
      // Wait 0.5 sec for above to finnish then do something
      if (typeof f !== 'undefined')
        f();

      setTimeout(() => nameField.focus(), 1000);
    }
  }

  useEffect(() => {
    refocusForm();
  }, [licenseFromMenuClick]);

  useEffect(() => {
    setValues((oldState: OrderInputProps) => ({ ...oldState, license: license.key }))
    updateOrderArray(oldState =>  oldState.map(orderValue => ({ ...orderValue, license: license.key })))
  }, [ license.key ])

  const removeClientFromArray = (c: OrderInputProps): void => {
    updateOrderArray((oa) => oa.filter((client) => client.email !== c.email));
  };

  const removeAndEditClientInArray = (c: OrderInputProps): void => {
    const clientToEdit: OrderInputProps = orderArray.filter((client) => client.email === c.email)[0];
    removeClientFromArray(clientToEdit);
    setValues(clientToEdit);
  }

  const addClientToArray = async(): Promise<boolean> => {
    if (orderArray.find((client) => client.email === values.email)?.email) {
      // return Promise.resolve();
      return false;
    }

    updateOrderArray((oa) => [...oa, values]);
    refocusForm(() => setValues((prev:OrderInputProps) => ({ ...prev, name: "", email: "" })));
    // return Promise.resolve()
    return true;
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === "Enter" && formIsValid(requiredFields)) {
      addClientToArray();
    }
  };

  // const keyEnd = (text :string): string => text.substring(text.length - 10)

  const termsAndSubmitButton = (): JSX.Element => (
    <>
    <Typography mt="1vh" mb="1vh" width="100%" />
      <LoadingFormButton
        sx={{ width: "100%" }}
        disabled={ (!formIsValid(requiredFields) && orderArray.length < 1) || license.pcs < orderArray.length }
        onClick={(): Promise<boolean> =>
          handleOrderFormSubmit(orderArray.length > 0 ? orderArray : [values])
          .then(success => {
            if (success) {
              browserHistory.push("/status", { fromOrder: true });
              return true;
            }
            return false;
          })
        }
      >
        <span>Skicka in beställning</span>
      </LoadingFormButton>
    </>
  );

  const isDisabled = (): boolean => !(userHasValidInvoiceInfo(user) && license.pcs > orderArray.length)

  const whyIsThisDisabledMessage = (): JSX.Element => {
    if (!userHasValidInvoiceInfo(user)) {
      return <Typography data-test='needs-settings-message'>
        Innan du kan lägga en beställning behöver du först ange
        kontaktinformation under <SLink href="/settings">Inställningar</SLink>,
        och därefter köpa licenser
      </Typography>
    }
    return <div/>
  }
  
  return (
    <Grid container mt="3vh" component="main" direction="row" spacing={4}>
      <Grid item md={12} lg={6} xl={4}>
        <Grid container direction="column" spacing={2}>
          <BuyLicenseModal refocus={refocusForm} license={license} orderArray={orderArray} disabled={!userHasValidInvoiceInfo(user)} xs={12}/>
          <Grid item xs={12}>{whyIsThisDisabledMessage()}</Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              disabled={isDisabled()}
              inputProps={{ "data-test": "name-input" }}
              required
              fullWidth
              id="name"
              name="name"
              value={values.name}
              label="Namn"
              helperText={<span style={{ color:"red" }}>{errors.name}&nbsp;</span>}
              inputRef={nameRef}
              onChange={handleInputValue} 
              onBlur={handleInputValidation}
              onKeyDown={handleKeyDown}
            />
            <RadioGroup
              row 
              id="gender" 
              name="gender" 
              value={isDisabled() ? 'disabled' : values.gender} 
              onChange={handleInputValue}
              >
              <FormControlLabel value="female" control={<Radio  data-test="gender-input"/>} label="Kvinna" />
              <FormControlLabel value="male" control={<Radio data-test="gender-input"/>} label="Man" />
              <FormControlLabel value="other" control={<Radio data-test="gender-input"/>} label="Annat" />
            </RadioGroup>
            <TextField
              margin="normal"
              disabled={isDisabled()}
              inputProps={{ "data-test": "email-input" }}
              required
              fullWidth
              id="email"
              type="email"
              label="E-post"
              name="email"
              value={values.email}
              onChange={handleInputValue} 
              onBlur={handleInputValidation}
              onKeyDown={handleKeyDown}
              helperText={<span style={{ color:"red" }}>{errors.email}&nbsp;</span>}
            />
            <TextField
              margin="normal"
              disabled={isDisabled()}
              fullWidth
              inputProps={{ "data-test": "company-input" }}
              id="company"
              label="Företag"
              name="company"
              value={values.company}
              onChange={handleInputValue} 
              onBlur={handleInputValidation}
              onKeyDown={handleKeyDown}
              helperText={<span>Företagsnamnet anges på försättsbladet av rapporten, ingen annanstans, och kan utelämnas om du vill.</span>}
            />
            { license.languages.length > 1 && (
              <Box mt="15px" sx={{ minWidth: "240px" }}>
                <FormControl fullWidth required >
                  <InputLabel id="languageLabel">Språk</InputLabel>
                  <Select
                    id="language"
                    inputProps={{ "data-test": "language-input" }}
                    disabled={isDisabled()}
                    name="language"
                    labelId="languageLabel"
                    value={values.language}
                    onChange={handleInputValue}
                    label="Språk"
                  >
                    {license.languages.map(language =>
                      <MenuItem key={language} value={language}>{language === 'swedish' ? 'Svenska' : 'Engelska'}</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Box>
            )}
            <DateField
              minDate={ today }
              label="Sista svarsdatum"
              value={ values.endDate }
              onChange={event => handleInputValue({ target: { name: "endDate", value: event } })}
              dataTest="end-input"
              helperText="Sista datum att besvara enkäten - rapporten finns först dagen efter."
              sx={{ minWidth: "240px", marginTop: "15px" }}
              disabled={isDisabled()}
            />
            <DateField
              label="Påminnelsedatum"
              maxDate={values.endDate}
              minDate={today}
              value={values.reminderDate}
              helperText="Ingen påminnelse? Lämna fältet ovan blankt."
              onChange={event => handleInputValue({ target: { name: "reminderDate", value: event } })}
              dataTest="reminder-input"
              sx={{ minWidth: "240px", marginTop: "15px" }}
              disabled={isDisabled()}
            />
            <Grid item xs={12}>
              {orderArray.length === 0 ? (
              <Grid container alignContent="center" alignItems="center">
                <Grid item xs={10}>{termsAndSubmitButton()}</Grid>            
                <Grid item xs={2} alignItems="flex-end">
                  <Tooltip
                    title={`Lägg till flera ${productCategory}ar`}
                    placement="right"
                  >
                    <span>
                      <LoadingFormButton 
                        dataTest='additional-order-button'
                        variant="text"
                        onClick={ addClientToArray } 
                        disabled={!formIsValid(requiredFields) || isDisabled() }
                      >
                        <Avatar sx={{
                          bgcolor: !formIsValid(requiredFields) || isDisabled() ? 
                            "rgba(0, 0, 0, 0.12)" : "#1976d2" 
                          }}
                        >
                          <FontAwesomeIcon icon="plus" />
                        </Avatar>
                      </LoadingFormButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              ) : (
                <Grid item xs={12}>
                  <LoadingFormButton 
                    variant="text" 
                    dataTest='one-additional-order-button' 
                    onClick={ addClientToArray } 
                    disabled={!formIsValid(requiredFields) || isDisabled()}
                  >
                    <>
                      <Avatar
                        className="hovering-pointer"
                        sx={{
                          marginRight: "15px",
                          bgcolor: !formIsValid(requiredFields) || isDisabled() ? 
                            "rgba(0, 0, 0, 0.12)" : "#1976d2" 
                        }}
                      >
                        <FontAwesomeIcon icon="plus" />
                      </Avatar>
                      Lägg till
                    </>
                  </LoadingFormButton>
                </Grid>
            )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item md={12} lg={6} xl={5}>
        <Grid container>
          {orderArray.length > 0 && (
            <Grid item xs={12}>
              <Typography component="h1" variant="h5" textAlign="center">
                Beställning
              </Typography>
              <List>
                  {orderArray.map((client) => (
                    <ListItem key={client.email} divider>
                      <ListItemIcon>
                        <FontAwesomeIcon
                          icon={
                            client.gender === "other"
                              ? "transgender"
                              : (client.gender as IconProp)
                          }
                        />
                      </ListItemIcon>
                      <ListItemText>
                        {client.name} {client.email}
                      </ListItemText>
                      <ListItemIcon>
                        <IconButton size="small" onClick={() => removeAndEditClientInArray(client)}>
                          <FontAwesomeIcon icon="wrench" />
                        </IconButton>
                      </ListItemIcon>
                      <ListItemIcon>
                        <IconButton size="small" onClick={() => removeClientFromArray(client)}>
                          <FontAwesomeIcon icon="trash-alt" />
                        </IconButton>
                      </ListItemIcon>
                    </ListItem>
                  ))}
                  </List>
                  {termsAndSubmitButton()}
            </Grid>
          )}
          </Grid>
        </Grid>
      </Grid>
)}

export { OrderPage };
