import { Grid, TextareaAutosize, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Email } from "../_interfaces/EmailInterface";
import { api } from "../_services/apiServices";
import { LoadingFormButton } from "./LoadingFormButton";
import { SuccessNotification } from "./SuccessNotification";
import { useForm } from "../_helpers/useForm";
import { License } from "../_interfaces";

const EmailForm = ({ email, setEmails, license, textColor }: {
  email: Email,
  setEmails: React.Dispatch<React.SetStateAction<Email[]>>,
  license: License,
  textColor: (email: any) => string | undefined
}): JSX.Element => {

  const { description, id, language } = email;

  const { values, valid, handleInputValues } = useForm({
    subject: { value: email.subject, error: "" },
    body: { value: email.body, error: "" }
  })

  useEffect(() => {
    handleInputValues({target: { name: "subject", value: email.subject }})
    handleInputValues({target: { name: "body", value: email.body }})
  }, [email])
  
  const [notification, toggleNotification] = useState({ message: 'Sparat!', open: false });

  // selenium addon seems to add a header to the iframe at load time, so it's hard to get to here..
  // remove seleniums addon in the iframe:
  // const iframeRef: React.MutableRefObject<HTMLIFrameElement | undefined> = useRef(); 
  // const iframeRef = useRef<any>(); 
  // useEffect(() => {
  //   console.log(iframeRef.current);
  // },[]);
  // console.log({values})

  const handleSubmit = async (): Promise<boolean> =>
      api().sendEmailForm({ subject: values.subject.value, body: values.body.value, description, id, language, license: license.key })
      .then(response => {
        if (response && response.id && response.id !== '') {
          setEmails((prev:Email[]) => {
            const newArray = prev.slice();
            const index = newArray.findIndex(e => e.id === response.id && e.language === response.language);
            if (index !== -1) {
              newArray[index] = response;
            }
            return newArray;
          })
          toggleNotification({ message:"Sparat!", open:true });
          return true;
        }
        console.log((response as any).errors)
        toggleNotification({ message:"FEL!", open:true });
        return false;
    })
  

  const handleCloseNotification = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      toggleNotification({ message: notification.message, open: false });
      return;
    }
    toggleNotification({ message: notification.message, open: false });
  }

  return (
    <>
      <TextField 
        id='subject'
        name='subject'
        label='Ämne/rubrik på meddelandet'
        value={values.subject.value}
        helperText={<span style={{ color:"red" }}>{ values.subject.error }</span>}
        onChange={handleInputValues} 
        fullWidth
        margin="normal"
      />
      <Grid container style={{ width:'100%', marginTop:'16px', marginBottom:'8px' }}>
        <Grid 
          item 
          xs={12}
          sm={values.body && values.body.value?.startsWith('<html>') ? 6 : 12}
        >
          <TextareaAutosize
            id='body'
            name='body'
            placeholder='Mailtext...'
            value={values.body.value}
            minRows={20}
            onChange={handleInputValues} 
            style={{ width:'100%', height:'100%' }}
            />
          
        </Grid>
        {values.body && values.body.value?.startsWith('<html>') &&
          <Box 
            component={Grid}
            style={{ minHeight:'306px', border:'1px solid grey' }}
            item 
            sm={6}
            xs={12}
            display={{xs:values.body.value?.startsWith('<html>') ? 'block' : 'none'}}
          >
            <Typography style={{ height:'10%', textAlign:'center', width:'100%' }}>Förhandsgranska html:</Typography>
            <iframe 
              sandbox=''
              srcDoc={values.body} 
              title='previewEmail' 
              id='previewEmail'
              style={{ width:'100%', height:'90%' }}
            />
          </Box>
      }
      </Grid>
      <LoadingFormButton
        sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
        disabled={!valid}
        onClick={handleSubmit}
      >
        Spara
      </LoadingFormButton>
      <SuccessNotification
        open={notification.open}
        handleClose={handleCloseNotification}
        message={notification.message}
      />
    </>
  );
}

export { EmailForm };