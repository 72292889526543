import { AlertColor, Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { LoadingFormButton } from "./LoadingFormButton";
import { modalStyle } from "../_styles/ModalStyle";
import { useForm } from "../_helpers/useForm";
import { api } from "../_services/apiServices";

export const ChangeEmailModal = ({ setNotification, oldEmail }: { 
  setNotification: React.Dispatch<React.SetStateAction<{ open: boolean, message: string, severity: AlertColor }>>, oldEmail: string}): JSX.Element => {

  const emailRef = useRef<HTMLInputElement>();
  const { values, valid, handleInputValidation, handleInputValues } = useForm({ email: { error: "", value:  "" }})

  const handleChangeEmailButtonClicked = async(): Promise<boolean> => 
    api().sendChangeEmailAddressForm(values.email.value)
      .then(({ status }) => {
        if (status === 'pending') {
          setNotification({ open: true, message: "Du har mail. Kom ihåg att klicka på länken för att ändra din epost", severity: 'success' })
          handleClose()
        }
        return false;     
      }
      ).catch(error => {
        console.log(error)
        alert(`Lyckades inte spara ny epost: ${error}`)
        return false;
      })

      const [open, setOpen] = useState(false);
      const handleOpen = (): void => {
        setOpen(true);
        setTimeout(() => {
          const emailField = emailRef.current;
          if (emailField) {
            setTimeout(() => emailField.focus(), 300);
          }        
        }, 50)
      }
      const handleClose = (): void => setOpen(false);
    

  return (
    <>
      <Button onClick={handleOpen} data-test="change-email-button" fullWidth variant="contained" sx={{ padding:'1vh' }} >Byt epost</Button>
      <Modal
        open={open}
        data-test='forgotten-password-modal'
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
          <Typography>
            När du ändrar epost nedan går ett mail iväg till din nya adress.
            Mailet innehåller en länk som du behöver klicka på inom 30 minuter
            för att ändringen ska gå igenom - annars behåller du din nuvarande
            mailadress:<br/>{oldEmail}
          </Typography>
          <Typography mt='2vh'>
            * <i>Efter en ändring av epostadressen här kommer också mail som skickas härifrån innehålla din nya adress
            förutsatt att du har angivit din epost som [[min epost]] i epost-mallarna.</i>
          </Typography>
          <TextField 
            name='email'
            inputProps={{ 'data-test': 'email-input' }}
            inputRef={emailRef}
            label='ny.epost@example.com'
            margin='normal'
            value={values.email.value}
            helperText={<span style={{ color:"red" }}>{values.email.error}</span>}
            onChange={(e) => {
              handleInputValues(e);
              handleInputValidation(e);
            }}
            type='email'
            fullWidth
          />
          <LoadingFormButton
            sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
            disabled={!valid}
            onClick={handleChangeEmailButtonClicked}
          >
            Maila mig en länk!
          </LoadingFormButton>
        </Box>
      </Box>
    </Modal>
  </>
  );
}