import { useEffect, useMemo, useState } from "react";
import "./App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faHome,
  faAngleRight,
  faThermometerHalf,
  faPlusCircle,
  faSave,
  faSignInAlt,
  faSignOutAlt,
  faCogs,
  faBars,
  faMale,
  faFemale,
  faTransgender,
  faTrashAlt,
  faPlus,
  faAngleDown,
  faAngleUp,
  faEnvelope,
  faWrench,
  faTimesCircle,
  faFunnelDollar,
  faSlidersH,
  faListCheck,
  faPlay,
  faFlagCheckered,
  faCircleNodes,
  faImage,
  faCaretDown,
  faCaretUp,
  faDownload,
  faTriangleExclamation,
  faCircleInfo,
  faCookie,
  faMinusCircle,
  faMinus,
  faFileText,
  faArrowRight,
  faCheck,
  faPersonCirclePlus,
  faFilePdf,
  faFileArrowUp
} from "@fortawesome/free-solid-svg-icons";
import browserHistory from "./_helpers/History";
import { PagePresenter } from "./_components/PagePresenter";
import { LanguageContext } from "./_contexts/LanguageContext";
import { CookiesBanner } from "./_components/CookiesBanner";

library.add(
  faHome,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faThermometerHalf,
  faPlusCircle,
  faMinusCircle,
  faSignInAlt,
  faSignOutAlt,
  faCogs,
  faWrench,
  faBars,
  faSave,
  faMale,
  faFemale,
  faTransgender,
  faTrashAlt,
  faPlus,
  faMinus,
  faEnvelope,
  faTimesCircle,
  faFunnelDollar,
  faSlidersH,
  faListCheck,
  faPlay,
  faFlagCheckered,
  faCircleNodes,
  faImage,
  faCaretDown,
  faCaretUp,
  faDownload,
  faTriangleExclamation,
  faCircleInfo,
  faCookie,
  faFileText,
  faArrowRight,
  faCheck,
  faPersonCirclePlus,
  faFilePdf,
  faFileArrowUp
);

// declare module "@mui/material/styles" {
//   interface Theme {
//     status: {
//       danger: string;
//     };
//   }
//   // allow configuration using `createTheme`
//   interface ThemeOptions {
//     status?: {
//       danger?: string;
//     };
//   }
// }

function App(): JSX.Element {
  const [currentLocation, setLocation] = useState(browserHistory.location);
  const [language, changeLanguage] = useState("swedish");
  const langValue = useMemo(() => ({ language, changeLanguage }), [language])

  // resetting location on page reload. Needed for watching the terms page in a new window for example.
  useEffect(() => {
    const unlisten = browserHistory.listen(({ location }) => {
        // console.log('location changing to:')
        // console.log(location)
        setLocation(location); // <-- problem?
    });
    return unlisten;
  }, []);
  // console.log({currentLocation})

  return (
    <LanguageContext.Provider value={langValue}>
      <PagePresenter location={currentLocation} />
      <CookiesBanner />
    </LanguageContext.Provider>
  )
}

export default App;