import { useState } from "react";
import { Box, Typography, Modal, FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { modalStyle as style } from "../_styles/ModalStyle";
import "../_styles/Style.css";
// import { useUser } from "../_hooks/useUser";
import browserHistory from "../_helpers/History";
import { LoadingFormButton } from "./LoadingFormButton";
import { SLink } from "./SLink";
import { useForm } from "../_helpers/useForm";
import { loginOrSignUp } from "../_services/authenticationService";

export function NewAccountModal({ showModal, mutate }: { showModal?: string, mutate: any }): JSX.Element {

  // const { mutate } = useUser(true);

  const defaults = {
    email: { value: showModal || "", error: "" },
    password: { value: "", error: "" },
    confirmPassword: { value: "", error: "" },
  }

  // validation occurs on values not yet set. That is why newValues are submitted as a parameter here
  const extraCheck = (key: string, newValues: any): string | undefined => {
    const { [key]: { value } } = newValues
    if (key === 'confirmPassword') {
        if (value !== newValues.password.value) {
            return `Lösenordet stämmer inte med det ovan`
        }
    }
    if (key === 'password') {
      if (newValues.confirmPassword.value !== "" && value !== newValues.confirmPassword.value) {
          return `Lösenordet stämmer inte med det nedan`
      }
    }
    return undefined
  }
  
  const { values, valid, handleInputValidation, handleInputValues } = useForm(defaults, extraCheck)

  const fields = [
    { label: 'E-post', name: 'email', testdata: 'new-account-email-input' },
    { label: 'Välj ett lösenord', name: 'password', testdata: 'new-account-password-input' },
    { label: 'Upprepa lösenord', name: 'confirmPassword', testdata: 'new-account-password2-input' }
  ];

  // const [open, setOpen] = useState(showModal !== (undefined || "") || false);
  const [open, setOpen] = useState((showModal !== undefined && showModal !== "") || false);
  const [newAccountError, showError] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [enterKeyPressed, setEnterKeyPressed] = useState(false);

  const handleClose = (): void => setOpen(false);

  const submitOnKeyDown = (event: KeyboardEvent): void => {
    if (event.key === 'Enter') {
      setEnterKeyPressed(true);
    }
  }

  const handleSubmit = (): Promise<boolean> =>
      loginOrSignUp({ email: values.email.value, password: values.password.value, endpoint: 'signup' })
      .then(success => {
        if (success) {
          return mutate().then(() => {
            browserHistory.push('/login?create-account=true')
            return true;
          })
        }
        showError(true)
        return false
      })
      .catch(() => {
        showError(true)
        return false;
      })

  return (
    <div>
      <Modal
        data-test='new-account-modal'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5" data-test="new-account-title">
              Skapa konto
            </Typography>
            <br />
            <Typography>
              Tjänsten innehåller verktyg för att stödja coacher i sitt arbete. För att skapa ett konto hos oss, behöver
              du ha vissa kunskaper om coaching som du exempelvis kan få via en <SLink cypressTag='new-account-courses' href="./utbildning">utbildning</SLink>
            </Typography>
            <Box sx={{ mt: 1 }}>
              {fields.map((field, index) => 
                <TextField
                  key={field.name}
                  autoFocus={ index === 1 }
                  inputProps={{ "data-test": field.testdata }}
                  margin="normal"
                  required
                  fullWidth
                  label={field.label}
                  name={field.name}
                  value={values[field.name].value}
                  onChange={(e) => {
                    handleInputValues(e);
                    handleInputValidation(e);
                  }}
                  onKeyDown={(event:any) => submitOnKeyDown(event)}
                  autoComplete={field.name === "email" ? "email" : undefined}
                  disabled={field.name === "email" && showModal !== ""}
                  type={field.name === "email" ? "email" : passwordType}
                  helperText={<span style={{ color:"red" }}>{values[field.name].error || '\u00A0'}</span>}
                  />
              )}
              <FormGroup>
                <FormControlLabel 
                  control={<Switch />} 
                  label="Visa lösenord"
                  checked={passwordType === "text"}
                  onChange={() => setPasswordType(passwordType === "password" ? "text" : "password")}
                />
              </FormGroup>
              <LoadingFormButton
                dataTest="new-account-submit"
                disabled={!valid}
                sx={{ width: "100%", mt: 3, mb: 2 }}
                onClick={ handleSubmit }
                triggerClick={enterKeyPressed}
                setTriggerClick={setEnterKeyPressed}
              >
                Skapa konto och logga in
              </LoadingFormButton>
              { newAccountError &&
                <Typography data-test='new-account-error' color='red'>
                  Addressen {values.email.value} är okänd för oss.
                  Är du utbildad coach och vill ha ett konto?
                  Hör av dig till { process.env.REACT_APP_SUPPORT_EMAIL }
                </Typography>
              }
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

NewAccountModal.defaultProps = {
  showModal: false
}
