import axios from "axios";
import { userHasRole } from "../_helpers/AccessRoles";
import { Relation } from "../_interfaces";
import { LoginCredentials } from "../_interfaces/AccountInterface";
import { User } from "../_interfaces/UserInterface";

const api = process.env.REACT_APP_API_URL;
// alert(process.env)
if (api === undefined) {
  throw new Error('url to api is not set at authentificationServices');
}

axios.defaults.withCredentials = true;

// const signoff = async (): Promise<boolean> => {  
//   console.log('in signoff')
//   sessionStorage.clear();
//   return axios.post(`${api}/logout`).then(() => true);
// };

const signoff = async (user: any): Promise<boolean> => {  
  sessionStorage.clear();
  if (!user) {
    // console.log('no user in signoff')
    // sessionStorage.clear();
    return false;
  }
  // console.log('user in signoff', user)
  // sessionStorage.clear();
  return axios.post(`${api}/logout`).then(() => true);
  // return axios.post(`${api}/logout`).then(res => {
  //   console.log('res in signoff', res)
  //   return true
  // });
};

export async function loginOrSignUp({ email, password, endpoint }: LoginCredentials): Promise<boolean> {
  return fetch({
    query: `{"email":"${email}", "password":"${password}"}`,
    endpoint
  });
}

export async function fetch({ query, endpoint }: { query: string, endpoint: string }): Promise<boolean> {
  const response = await axios.post(
    `${api}/${endpoint}`,
    { query },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch((error) => {
    console.error(`error in response at ${endpoint}`)
    console.error(error)
    throw error
  });
  const { data: { error, language: _language } } = response;
  if (error) {
    throw response.data
  }
  return true;
}

const linkLogin2 = async (hash: string): Promise<boolean> =>
  fetch({
    query: `{"hash": "${hash}"}`,
    endpoint: 'login'
  });

const getLandingPage = (user: User, hash?: string): { path: string, state?: any } => {
  if (userHasRole(user, 'client') && user.parts.length > 0) {
    if (hash) {
      const parts = user.parts.filter(part => part.link === hash);
      if (parts.length === 1) {
        if (parts[0].relation === Relation.self && !parts[0].started) {
          return { path: '/start' };
        }
        if (parts[0].relation === Relation.self && parts[0].client.handedIn) {
          return { path: '/analysis' };
        }
        return { path: '/form', state: { part: parts[0] } }
      }
    }
    if (user.parts.some(part => part.relation === Relation.self && !part.started)) {
      return { path: '/start' };
    }
    return ({path: '/form', state: { part: user.parts[0] } });
  }

  if (userHasRole(user, 'respondent') && user.parts.length > 0) {
    if (hash) {
      const parts = user.parts.filter(part => part.link === hash);
      if (parts.length === 1) {
        return { path: '/form', state: { part: parts[0] } }
      }
      return { path: '/form', state: { part: user.parts[0] } };
    }  
  }
  
  if (userHasRole(user, 'coach')) {
    if (
      user.name && user.name !== '' &&
      user.email && user.email !== '' &&
      user.address && user.address !== '' &&
      user.postal && user.postal !== '' &&
      user.city && user.city !== '' &&
      user.company && user.company !== '' 
    ) {
      if (user.licenses.length > 0) {
        return { path: '/status', state: { fromLogin: true } }
      }
    }
    return { path: '/settings' };
  }

  if (userHasRole(user, 'admin')) {
    return { path: '/portal' };
  }
  // return { path: '/login' };
  return { path: '/logout' };
}

export { 
  signoff, 
  getLandingPage, 
  linkLogin2, 
};
