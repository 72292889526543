import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

import { pathAttributeMap } from "./Routing";
import browserHistory from "../_helpers/History";

import { getLandingPage, linkLogin2, signoff } from "../_services/authenticationService";
import { userHasAccess } from "../_helpers/AccessRoles";
import { MainMenu } from "./MainMenu";

import { PortalPage } from "../_pages/PortalPage";
import { OrderPage } from "../_pages/OrderPage";
import { StatusPage } from "../_pages/StatusPage";
import { LoginPage } from "../_pages/LoginPage";
import { SettingsPage } from "../_pages/SettingsPage";
import { EditPage } from "../_pages/EditPage";
// import { LicensePage } from "../_pages/LicensePage";
import { FormPage } from "../_pages/FormPage";
import { ChooseRespondentsPage } from "../_pages/ChooseRespondentsPage";
import { Part, Relation } from "../_interfaces";
import { TestPage } from "../_pages/TestPage";
import { useUser } from "../_hooks/useUser";
import { ThanksPage } from "../_pages/ThanksPage";
import { ErrorPage } from "../_pages/ErrorPage";
import { Spinner } from "./Spinner";
import { LogoutPage } from "../_pages/LogoutPage";
import { EducationPage } from "../_pages/EducationPage";
import { TermsPage } from "../_pages/TermsPage";
// import { PolicyPage } from "../_pages/PolicyPage";
import { CookiesPage } from "../_pages/CookiesPage";
import { NoMenu } from "./NoMenu";
import { FaqPage } from "../_pages/FaqPage";

import menuStrings from "../_strings/MainMenu.json";
import { LanguageContext } from "../_contexts/LanguageContext";
// import { AccountPage } from "./ChangeEmailModal";
import { ConfirmationPage } from "../_pages/ConfirmationPage";
import { ClientStatusPage } from "../_pages/ClientStatusPage";
import { GDPRPage } from "../_pages/GDPRPage";

export interface PagePresenterInterface {
  location: {
    search: string
    pathname: string
    state: any
  }
  // setLanguage: any
}

// export function PagePresenter({ location, setLanguage }: PagePresenterInterface): JSX.Element {
export function PagePresenter({ location }: PagePresenterInterface): JSX.Element {
  // console.log({location});

  // console.log('rendering PagePresenter')

// export function PagePresenter(): JSX.Element {
  // const [location, setLocation] = useState(browserHistory.location);

  // const mounted = useRef(false);
  // useEffect(() => {
  //   mounted.current = true;
  //   return () => { mounted.current = false }
  // }, []);

  const { user, loggedOut, mutate, logout } = useUser(true);
  
  // console.log({user, location})
  // const { user, loggedOut, mutate } = { user: {} as User, loggedOut: false, mutate: async() => Promise.resolve(true) };

  const [ spinner, showSpinner ] = useState(false);
  const [ waitingForComputations, setWaitingForComputations] = useState(false);
  const [ resettingCookies, setResettingCookies ] = useState(false);

  const { language, changeLanguage: setLanguage } = useContext(LanguageContext);

  // const [ key, setKey ] = useState("")
  // const [ hash, setHash ] = useState("")
  const msBeforeDisplayingSpinner = 200;

  const login = location.search.substring(1).split('&')[0];
  const [key, hash] = login.split("=");
  const sorryContent = <h2>Sorry, no access</h2>;

  let content = sorryContent;

  // useEffect(() => {
  //   const login = location.search.substring(1).split('&')[0];
  //   const [_key, _hash] = login.split("=");
  //   setKey(_key)
  //   setHash(_hash)
  // }, [location.search])

  let mounted = true
  useEffect(() => () => { mounted = false }, [])

  // performing computations for a while?
  useEffect (() => {
    // console.log('waitingForComputations: ', waitingForComputations)
    if (waitingForComputations) {
      showSpinner(true);
    }
  }, [waitingForComputations]);


  // Logging in via link?
  useEffect (() => {
    // console.log('loggin in via link, key: ', key)
    if (location.pathname === "/" && mounted) {
      // console.log('empty path')
      browserHistory.replace("/login")
    }
    if (key === 'access') {
      setTimeout(() => {
        if (mounted) {
          setWaitingForComputations(true)
        }
      }, msBeforeDisplayingSpinner);
      setWaitingForComputations(true)
      // added a mutation after signoff. This might not be necessary, but it helps when testing -
      // avoiding weird problems whith lingering logins.
      signoff(user).then(() => mutate().then(() => 
        linkLogin2(hash).then(() => {
          if (mounted) {
            mutate().then(() => {
              if (mounted) {
                setResettingCookies(true)
              }
            })
          }
        }).catch(error => {
          if (mounted) {
            setLanguage(error.language || "swedish")
            browserHistory.replace('/error', error.error)
          }
        })
      ))
    }
    // return () => { mounted = false }
  }, []);

  useEffect(() => {
    // console.log('in useEffect for create-account and access')
    // let mounted = true;
    // if (user && !loggedOut && key === 'create-account' && resettingCookies && mounted) {
    if (user && !loggedOut && key === 'create-account' && mounted) {
      setWaitingForComputations(true)
      // setTimeout just to create an extra delay when creating an account.
      setTimeout(() => {
        if (mounted) {
          const { path, state } = getLandingPage(user);          
          browserHistory.replace(path, state);
        }
      }, 1000);
    }
    // console.log({user, loggedOut, key, resettingCookies})
    if (user && !loggedOut && key === 'access' && resettingCookies && mounted) {
      const { path, state } = getLandingPage(user, hash);
      // console.log('landing page is ', path, state)
      if (mounted) {
        if (path === '/start') {
          const part = user.parts.find(p => p.relation === Relation.self)
          setLanguage(part?.language || "swedish");
        } else if (path === "/form") {
          setLanguage(state.part.language);
        }
        browserHistory.push(path, state);
      }
    }
    // return () => { mounted = false }
  }, [user, loggedOut, location, resettingCookies]);

  // const doSignoff = (): void => {
  //   signoff(user).then(success => {
  //     if (success) {
  //       // console.log('logged out!')
  //       mutate().then(() => {
  //         // console.log('mutated after logout')
  //       });
  //     }
  //     if (mounted) {
  //       browserHistory.replace('/login');
  //     }
  //   });      
  // }
  const doSignoff = (): void => {
    logout().then(() => {
      if (mounted) {
        browserHistory.replace('/login');
      }
    });      
  }

  useEffect(() => {
    // console.log('in useEffect for loggedOut')
    // let mounted = true;
    // if (loggedOut && location.pathname === "/logout") {
    // if (location.pathname === "/logout" && !loggedOut) {
      if (location.pathname === "/logout") {
        // console.log('logging out.....mounted: ', mounted)
        doSignoff();
        // logout();
        // signoff(user).then(success => {
        //   if (success) {
        //     console.log('logged out!')
        //     mutate().then(() => {
        //       console.log('mutated after logout')
        //     });
        //   }
        //   if (mounted) {
        //     browserHistory.replace('/login');
        //   }
        // });      
    }
    else if (loggedOut) {
      // console.log('logged out.....mounted: ', mounted)
      // if (location.pathname === "/logout" && mounted) {
        // console.log('not replacing login')
        // browserHistory.replace('/login'); 
        // signoff(user).then(success => {
        //   if (success) {
        //     console.log('logged out!')
        //     mutate()
        //     // browserHistory.replace('/login');
        //     // location.pathname = '/login';
        //   }
        //   if (mounted) {
        //     browserHistory.replace('/login');
        //   }
        //   // mutate()
        // });
    
      // }
      // else 
      if (pathAttributeMap[location.pathname] && !pathAttributeMap[location.pathname].access.includes("public")) 
      {
        // user has answered and is at ThanksPage --> dont redirect, just sign off
        if ((location.pathname === "/form" && !userHasAccess(user, location.pathname))) {
          // signoff(user)
          logout();
          // signoff().then(() => browserHistory.replace('/thanks');
        }
        else {
          doSignoff();
          // signoff(user)
          // if (mounted) {
          //   browserHistory.replace('/login');
          // }
        }
        // browserHistory.replace('/logout');         
      }
      // else {
      //   signoff()
      //   browserHistory.replace('/login'); 
      // }
    }
    // return () => { mounted = false }
  }, [loggedOut, location])

  // const [pathname, replacePathname] = useState(browserHistory.location.pathname)
  // const [redirectToLogin, doRedirect] = useState(false)
  
  // useEffect(() => {
  //   // setLocation(pathname)
  //   console.log('doing the redir')
  //   // if (redirectToLogin) {
  //   browserHistory.replace('/login')
  //   //   doRedirect(false)
  //   // }
  // }, [redirectToLogin])


  if (location.pathname === "/login") {
    if (key === 'access' && userHasAccess(user, location.pathname)) {
      content = spinner ? <Spinner text='Loggar in...' /> : <div/>
      // ( <Box sx={{ display: 'flex', justifyContent:'center', height: '100vh', alignItems:'center' }}>
      //     <Typography variant='h5' mr='16px'>Loggar in...</Typography> <CircularProgress />
      //   </Box> ) : <div />
    }
    else if (key === 'create-account' && userHasAccess(user, location.pathname)) {
      content = spinner ?
      ( <Box sx={{ display: 'flex', justifyContent:'center', height: '100vh', alignItems:'center' }}>
          <Typography variant='h5' mr='16px'>Skapar konto...</Typography> <CircularProgress />
        </Box> ) : <div />
    }
    else if (key === 'signup' && userHasAccess(user, location.pathname)) {
      content = userHasAccess(user, location.pathname) ? <LoginPage signup={hash}/> : content;
    }
    else {
      // console.log('location, browserHistory.location')
      // console.log(location, browserHistory.location)
      // content = userHasAccess(user, location.pathname) ? <LoginPage signedOut={location?.state?.signedOut}/> : content;
      content = userHasAccess(user, location.pathname) ? <LoginPage/> : content;
    }
  }
  else if (location.pathname === "/") {
    if (userHasAccess(user, location.pathname)) {
  //     // if (mounted.current) {
  //     //   browserHistory.replace("/login")
  //     // }
  //     // window.location.replace('/login')
  //     // replacePathname('/login');
  //     // doRedirect(true)
      content = <LoginPage />;
    }
  }
  else if (location.pathname === "/logout") {
    // console.log('logging out')
    // signoff(user).then(success => {
    //   if (success) {
    //     console.log('logged out!')
    //     mutate()
    //     // browserHistory.replace('/login');
    //     // location.pathname = '/login';
    //   }
    //   if (mounted) {
    //     browserHistory.replace('/login');
    //   }
    //   // mutate()
    // });
    // console.log('signing off')
    // signoff();
  //   // mutate();
  //   // for (let i = 0; i < 100; i += 1) {
  //   //   // delay 
  //   // }
  //   // mutate();
  //   // // browserHistory.push("/login");
  //   browserHistory.replace("/login");
  //   // // window.location.replace('/login')
  //   // // doRedirect(true)
  //   // // replacePathname('/login');
  //   content = <LoginPage signedOut/>;
  //   // content = <LoginPage />;
    content = <LogoutPage />;
  } 
  else if (location.pathname === "/test") {
    content = <TestPage />;
  } else if (location.pathname === "/order") {
    // console.log('order page in PagePresenter')
    if (userHasAccess(user, location.pathname)) {
      let license = user.licenses[0]; // if altering path in browser, something should still show
      try {
        ({ license } = location.state); // <-- Risks TypeError: Cannot destructure property 'license'...
      }
      catch (error) {
        // console.log('error at PagePresenter. Cannot show order page. No license?')
        // console.log({user, location, error})
      }
      if (license) {
        content = 
        <MainMenu 
          page = {<OrderPage license={license} />} 
          head = {`${pathAttributeMap[location.pathname].label(license)}`} 
        />;
      }
    }
    // console.log(user, location.pathname)
    // console.log('content')
    // console.log(content)
} else if (location.pathname === "/status") {
  if (userHasAccess(user, location.pathname)) {
    let page = <StatusPage />
    if (location.state && location.state.fromLogin) {
      page = <StatusPage fromLogin />
    }
    else if (location.state && location.state.fromOrder) {
      page = <StatusPage fromOrder />
    }
    content = <MainMenu 
      page = { page } 
      head = {`${pathAttributeMap[location.pathname].label(undefined)}`} 
    />
  }
} else if (location.pathname === "/account") {
    // content = userHasAccess(user, location.pathname) ? (
    //   <MainMenu page={<AccountPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
    // ) : (
    //   content
    // );
    content = userHasAccess(user, location.pathname) ? (
      <MainMenu page={<SettingsPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
    ) : (
      content
    );
  } else if (location.pathname === "/settings") {
    content = userHasAccess(user, location.pathname) ? (
      <MainMenu page={<SettingsPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
    ) : (
      content
    );
  } else if (location.pathname === "/edit") {
    const { license } = location.state;

    if (license && userHasAccess(user, location.pathname)) {
      content = 
      <MainMenu 
        page={<EditPage license={license}/>} 
        head={`${pathAttributeMap[location.pathname].label(license)}`}
      />
    }
  }
  // else if (location.pathname === "/license") {
  //   content = userHasAccess(user, location.pathname) ? (
  //     <MainMenu page={<LicensePage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
  //   ) : (
  //     content
  //   );
  // }
  else if (location.pathname === "/start") {
    // Warning: user becomes undefined on reload
    const notYetStarted = user && user.parts.some((part: Part) => part.relation === Relation.self && !part.started);
    // const part = user.parts.find(p => p.relation === Relation.self)
    content = userHasAccess(user, location.pathname) && notYetStarted ? (
      <MainMenu
        page={<ChooseRespondentsPage languages={['swedish', 'english']}/>}
        head={`${pathAttributeMap[location.pathname].label(language)}`}
      />
    ) : (
      content
    );
  } else if (location.pathname === "/form") {
    if (location.state.part && userHasAccess(user, location.pathname, location.state.part)) {
      content = (
        <MainMenu 
          page={
            <FormPage 
              user={user}
              part={location.state.part}
            />
          }
          head={`${pathAttributeMap[location.pathname].label(language)} ${location.state.part.relation === Relation.self ? menuStrings[language].yourOwnAnalysis : location.state.part.client.name}`}
        />
      )
    }
    else if (userHasAccess(user, '/analysis')) {
      browserHistory.replace('/analysis'); // <-- is this ok??
      content = (
        <MainMenu 
          page={ <ClientStatusPage/>}
          // head={`${pathAttributeMap[location.pathname].label(language)} ${location.state.part.relation === Relation.self ? menuStrings[language].yourOwnAnalysis : location.state.part.client.name}`}
          head={`${pathAttributeMap[location.pathname].label(language)}`}
        />
      )
    }
    else {
      content = (
      <MainMenu
        page = {<ThanksPage/>}
        head = { menuStrings[language].thanks }
      />
      )
    }
    // console.log({up: user.parts, lsp: location.state.part, access: userHasAccess(user, location.pathname, location.state.part)})
    // content = userHasAccess(user, location.pathname, location.state.part) ? (
    //   <MainMenu 
    //     page={
    //       <FormPage 
    //         user={user}
    //         part={location.state.part}
    //       />
    //     }
    //     head={`${pathAttributeMap[location.pathname].label(language)} ${location.state.part.relation === Relation.self ? menuStrings[language].yourOwnAnalysis : location.state.part.client.name}`}
    //   />
    // ) : (
    //   <MainMenu
    //     page = {<ThanksPage user={user} />}
    //     head = { menuStrings[language].thanks }
    //   />
    // );
  } 
  else if (location.pathname === "/analysis") {
    // Warning: user becomes undefined on reload
    // const notYetStarted = user && user.parts.some((part: Part) => part.relation === Relation.self && !part.started);
    // const part = user.parts.find(p => p.relation === Relation.self)
    // content = userHasAccess(user, location.pathname) && notYetStarted ? (
    content = userHasAccess(user, location.pathname) ? (
      <MainMenu
        page={<ClientStatusPage />}
        head={`${pathAttributeMap[location.pathname].label(language)}`}
      />
    ) : (
      content
    );
  }
  else if (location.pathname === "/portal") {
    content = userHasAccess(user, location.pathname) ? (
      <MainMenu page={<PortalPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
    ) : (
      content
    );
  }
  else if (location.pathname === "/error" && userHasAccess(user, location.pathname)) {
    content = <MainMenu page={<ErrorPage error={location.state} />} head='' />
    // content = <MainMenu page={<ErrorPage error={location.state} />} head='Oväntat fel' />
  }
  else if (location.pathname === "/terms" && userHasAccess(user, location.pathname)) {
    content = (location as any)?.key === 'default' ?
    <NoMenu page={<TermsPage />} dataTest='terms-page'/> :
    <MainMenu page={<TermsPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
  }
  else if (location.pathname === "/policy" && userHasAccess(user, location.pathname)) {
    // content = <MainMenu page={<PolicyPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
    content = <MainMenu page={<GDPRPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
  }
  else if (location.pathname === "/cookies" && userHasAccess(user, location.pathname)) {
    content = <NoMenu page={<CookiesPage />} dataTest='cookies-page' />
  }
  else if (location.pathname === "/gdpr" && userHasAccess(user, location.pathname)) {
    content = <NoMenu page={<GDPRPage />} dataTest='gdpr-page' />
  }
  else if (location.pathname === "/faq" && userHasAccess(user, location.pathname)) {
    content = <MainMenu page={<FaqPage />} head={`${pathAttributeMap[location.pathname].label(undefined)}`} />
  }
  else if (location.pathname === "/utbildning" && userHasAccess(user, location.pathname)) {
    content = <NoMenu page={<EducationPage />} dataTest='education-page' />
  }
  else if (location.pathname === "/change/email" && userHasAccess(user, location.pathname)) {
    content = <ConfirmationPage location={ location }/>
  }
  else if (location.pathname === "/change/password" && userHasAccess(user, location.pathname)) {
    content = userHasAccess(user, location.pathname) ? <LoginPage reset={hash}/> : content;
  }
  // else if (location.pathname === "/tack") {
  //   content = <MainMenu page={<ErrorPage error={location.state} />} head='Oväntat fel' />
  // }
  // else if (location.pathname === "/thanks") {
  //   content = <MainMenu page={<ErrorPage error={location.state} />} head='Oväntat fel' />
  // }
  else {
    content = <NoMenu page={<h1>Not Found</h1>} dataTest='not-found' />;
    // content = await setTimeout(() => <NoMenu page={<h1>Not Found</h1>} dataTest='not-found' />, 1000);
  }

  if (content === sorryContent) {
    return user === undefined ? <div /> : <div>{content}</div>
  }
  return <div>{content}</div>
}