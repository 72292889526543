import useSWR, { KeyedMutator } from "swr";
import { User } from "../_interfaces";
import { fetcher, userLogout } from "../_services/fetcher";
import { gqlRequest } from "../_services/gqlRequests";

export const useUser = (immutable?: boolean): 
{
    user: User, 
    loading: boolean, 
    loggedOut: boolean, 
    mutate: KeyedMutator<any>,
    isValidating: boolean,
    logout: () => Promise<void>
} => {

    const { data, mutate, error, isValidating } = useSWR({ query: gqlRequest.useUser }, fetcher, 
        immutable ?
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            // refreshInterval: 10000
        } : {
            // refreshInterval: 10000
        }
    );
    const loading = !data && !error;
    const loggedOut = error && error.status === 403; // || false;
    // const loggedOut = (!isValidating && !data) || (error && error.status === 403) || false;
    // const loggedOut = (isValidating && !data) || (error && error.status === 403) || true;
    // const loggedOut = !data || (error && error.status === 403) || false; // || true;

    // console.log('data, error, isValidating, loggedOut, loading')
    // console.log(data, error, isValidating, loggedOut, loading)
    
    // const logout = async () => {
    //     return axios.post(`${api}/logout`).then(() => true);
    // }
    const logout = async (): Promise<void> => {
        // await userLogout().then(() => mutate().then(() => console.log('mutated after logout')))
        await userLogout().then(() => mutate())
        .catch(e => {
            console.error('error in logout')
            console.error(e)
        });
    }
    // console.log('immutable', immutable)
    // console.log('data, error, isValidating, loggedOut, loading')
    // console.log(data, error, isValidating, loggedOut, loading)
    // console.log('data, isValidating, loggedOut, loading')
    // console.log(data, isValidating, loggedOut, loading)

    return {
        loading,
        loggedOut,
        user: data, // && data.data.user,
        mutate,
        isValidating,
        logout
    };
}