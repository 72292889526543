import { useContext } from "react";
import { Typography } from "@mui/material";
import strings from "../_strings/ThanksPage.json"
import { LanguageContext } from "../_contexts/LanguageContext";

export const ThanksPage = (): JSX.Element => {
    const { language } = useContext(LanguageContext)
    const message = strings[language]?.thanks || "Tack för dina svar!";
    return <Typography mt='10vh' variant='h5'>{ message }</Typography>
}
