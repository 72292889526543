import { Box, Grid, TextField, Typography, TextareaAutosize } from "@mui/material";
// import { TextareaAutosize } from "@mui/base";
import { useContext, useEffect, useState } from "react";
import { License } from "../_interfaces";
import { Form } from "../_interfaces/QuestionInterface";
import { api } from "../_services/apiServices";
import { LanguageContext } from "../_contexts/LanguageContext";

const QuestionsPage = ({ license }: { license: License }): JSX.Element => {

  const { getForm } = api();
  const [form, setForm] = useState({} as Form);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    getForm({ license })
      .then(f => setForm(f))
      .catch(e => alert(e));
  }, [license]);

  return (
    <>
      {form && form.sections &&
      <>
        <Typography variant='h5'>360&deg;-kartläggning: <span style={{color:"rgb(84, 150, 168)"}}>{form.name}</span></Typography>
        {form.sections.map( section =>
        <>
          <TextField 
            id='section'
            name='section'
            label='Kategori'
            value={section.text[language]}
            fullWidth
            margin="normal"
          />
          {section.groups.map( group =>
          <>
            <TextField 
              id='group'
              name='group'
              label='Område'
              value={group.text[language]}
              fullWidth
              margin="normal"
            />
            {group.questions.map( question =>
            <Grid container 
            >

              {/* On large screens, to the left of the TextArea, show #. */}
              <Box component={Grid} item display={{xs:'none', sm:'block'}} sm={2} md={1}>
                <Typography style={{display:'flex', justifyContent:'center'}}>{question.index + 1}.</Typography>
              </Box>

              {/* On smaller screens, on top of Textarea, show Fråga #. */}
              <Box component={Grid} item xs={12} display={{sm:'none'}}>
                <Typography>Fråga {question.index + 1}:</Typography>
              </Box>

              <Grid item xs={12} sm={10} md={11}>
                <TextareaAutosize
                  id='body'
                  name='body'
                  placeholder={`Fråga ${question.index}`}
                  defaultValue={question.text[language]}
                  minRows={3}
                  style={{width:'100%'}}
                />
              </Grid>
            </Grid>    
            )}
          </>
          )}
          <hr style={{height:'3px', color:'grey', backgroundColor:'grey', marginTop:'6vh'}} />
        </>
        )}
      </> 
    }
    <div/>
  </>
  );
}

export { QuestionsPage };
