import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, FormControl, Grid, IconButton, ListItemIcon, MenuItem,
  Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { AlertColor } from "@mui/lab";
import { Box } from "@mui/system";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { dateFormat } from "../_helpers/Dateformat";
import { api } from "../_services/apiServices";
import { Gender, nameOfRelation, OrderProps, Relation, Respondent } from "../_interfaces";
import { SuccessNotification } from "../_components/SuccessNotification";
import browserHistory from "../_helpers/History";
import { useUser } from "../_hooks/useUser";
import { Spinner } from "../_components/Spinner";
import { LoadingIconButton } from "../_components/LoadingIconButton";
import { DateField } from "../_components/DateField";

function Progress({ respondents, client }: { respondents: Respondent[], client: Respondent }): JSX.Element {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    border: "1px solid black",
    height: "40px",
    width: "40px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const answered = respondents.filter(r => r.handedIn).length + (client.handedIn ? 1 : 0);
  const total = respondents.length + 1;

  const Circle = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    color: theme.palette.text.secondary,
    border: "1px solid black",
    height: "50px",
    width: "50px",
    borderRadius: "25px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  return (
    <div style={{ width: "68px" }}>
      <Circle>{answered}/{total}</Circle>
    </div>
  );
}

const genderAsString = (gender: string): string => {
  if (gender === 'female') {
    return 'kvinna'
  }
  if (gender === 'male') {
    return 'man'
  }
  return 'okänt/annat'    
}

const getGenderIcon = (gender: string): JSX.Element =>
  <Tooltip title={genderAsString(gender)}>
    <ListItemIcon>
      <FontAwesomeIcon icon={gender === 'other' ? 'transgender' : gender as IconProp}/>
    </ListItemIcon>
  </Tooltip>


function RespondentRow(props: {
  busyEditing: boolean, 
  toggleBusyEditing: Dispatch<SetStateAction<boolean>>,
  email: string,
  relation: string,
  language: string,
  name: string,
  order: OrderProps,
  changeOrders: Dispatch<SetStateAction<OrderProps[]>>,
  setNotification: React.Dispatch<React.SetStateAction<{
    open: boolean
    message: string
    severity: string
    hideAfterXSeconds: number
  }>>,
  disabled: boolean
}) : JSX.Element {

  const { 
    busyEditing, 
    toggleBusyEditing, 
    order, 
    changeOrders, 
    email: savedEmail, 
    relation: savedRelation, 
    name: savedName,
    language: savedLanguage,
    setNotification,
    disabled
  } = props;

  const { editRespondent, sendEmail } = api();

  const [ isEditing, toggleIsEditing ] = useState(false);
  const [ name, setName ] = useState(savedName);
  const [ email, setEmail ] = useState(savedEmail);
  const [ relation, setRelation ] = useState(savedRelation);
  const [ language, setLanguage ] = useState(savedLanguage);
  const [save, doSave] = useState(false);

  const mountedRef = useRef(true);
  let timeoutId: ReturnType<typeof setTimeout>;

  const handleEditOrSave = (event : React.MouseEvent<HTMLElement>) : void => {
    if (disabled) {
      return;
    }
    // enable edit:
    if (!busyEditing) { 
      toggleBusyEditing(true);
      if (mountedRef.current) {
       toggleIsEditing(true);
      }
    }
    // save:
    else if (isEditing) { // } && mountedRef.current) {
      doSave(true);
    }
  }

  // let mounted = true
  // useEffect(() => () => { mounted = false }, [])

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // let mounted = true;
    if (save) {
      editRespondent({ 
        oldValues: { email: savedEmail, name: savedName, relation: savedRelation, language: savedLanguage } as Respondent, 
        newValues: { email, name, relation, language } as Respondent, 
        order
      }).then( ({ name: thisName, email: thisEmail, language: thisLanguage, relation: thisRelation }: Respondent) => {
        // if (mountedRef.current) {
          setNotification({ open: true, message: 'Sparat!', severity:'success', hideAfterXSeconds: 3 })
          toggleIsEditing(false);
          timeoutId = setTimeout(() => {
            toggleBusyEditing(false);
          }, 2000);
          // setTimeout(() => {
            changeOrders( prev => {
              const changed = [...prev];
              const thisOrder = changed.filter( o => o.id === order.id)[0];
              const thisRespondent = thisOrder.respondents.filter( r => r.email === savedEmail)[0];
              thisRespondent.email = thisEmail;
              thisRespondent.name = thisName;
              thisRespondent.language = thisLanguage;
              thisRespondent.relation = thisRelation;
              return changed;
            });
          // }, 2000);
    }).catch(error => {
      if (mountedRef.current) {
        setNotification({
          open: true, 
          message: `Något gick fel när ändringarna skulle sparas. Felmeddelande: ${error}`, 
          severity: 'error', 
          hideAfterXSeconds: 15
        })
      }
        // alert(`Något gick fel när ändringarna skulle sparas. Felmeddelande: ${error}`)
      }).finally(() => {
        // console.log('finally')
        // console.log(mountedRef.current)
        // if (mountedRef.current) {
        //   toggleIsEditing(false);
        //   timeoutId = setTimeout(() => {
        //     toggleBusyEditing(false);
        //   }, 2000);
        // }
      });
      doSave(false);
    }
    // return () => { mounted = false }
  }, [save]);

  // const [delay, triggerDelay] = useState(false);
  // useEffect(() => {
  //   let mounted = true;
  //   if (delay) {
  //     setTimeout(() => {
  //       if (mounted) {
  //         toggleBusyEditing(false);
  //       }
  //     }, 2000);
  //     triggerDelay(false)
  //   }
  //   return () => { mounted = false }
  // }, [delay])

  return (
    <TableRow
      data-test="respondent-row"
      sx={{
        "&:last-child td, &:last-child th": {
          border: "none",
        },
      }}
    >
    <TextTableCell
      dataTest="name-field"
      isEditing={isEditing}
      value={savedName}
      onChange={(event) => setName(event.target.value)}
    />
    <TextTableCell
      dataTest="email-field"
      isEditing={isEditing}
      value={savedEmail}
      onChange={(event) => setEmail(event.target.value)}
    />
    <SelectTableCell
      dataTest="relation-field"
      isEditing={isEditing}
      value={relation}
      onChange={(event) => setRelation(event.target.value)}
      list={Object.values(Relation).filter(n => n !== 'self')}
      labelCallback={(relationString: string) => nameOfRelation(relationString)}
    />
    <SelectTableCell
      value={language || 'swedish'}
      isEditing={isEditing}
      onChange={(event) => setLanguage(event.target.value)}
      list={order.languageOptions}
      labelCallback={languageString}
      dataTest="language-input"
    />
    <TableCell>
      <LoadingIconButton
        tooltip={`Skicka om senast skickade mail till ${name}`}
        dataTest="email-button"
        disabled={order.ended} 
        onClick={() => !disabled && email && sendEmail('respondent', email, order).then(failList => {
          if (failList.length === 0) {
            setNotification({
              open: true, 
              message: "Skickade email!", 
              severity:'success', 
              hideAfterXSeconds: 3
            })
          }
          else {
            setNotification({
              open: true, 
              message: `Kunde inte skicka email till ${failList[0]}!`, 
              severity: 'error', 
              hideAfterXSeconds: 5
            })
            // alert(`Kunde inte skicka email till ${failList[0]}!`)
          }
        })
      }      >
        <FontAwesomeIcon icon="envelope" />
      </LoadingIconButton>
    </TableCell>
    <TableCell>
      <Tooltip title={ isEditing ? "Spara" : "Ändra" }>
        <span>
          <IconButton 
            disabled={(!isEditing && busyEditing) || disabled} 
            onClick={ (event:React.MouseEvent<HTMLElement>) => handleEditOrSave(event) }>
          { isEditing ? 
            <FontAwesomeIcon icon="save" data-test="save-button"/> :
            <FontAwesomeIcon icon="wrench" data-test="edit-button"/>
          }
          </IconButton>
        </span>
      </Tooltip>
    </TableCell>
  </TableRow>
  );
}

const languageString = (theLanguage: string | null | undefined): string =>
  theLanguage === 'english' ? 'Engelska' : 'Svenska'

const SelectTableCell = ({ isEditing, value, onChange, list, labelCallback, dataTest }: {
  isEditing: boolean,
  value: string,
  onChange: any,
  list: string[],
  dataTest: string,
  labelCallback?: any
}): JSX.Element =>
  <TableCell align="center">
  { isEditing ?
  <FormControl>
    <Select
      value={value}
      onChange={onChange}
    >
      {list && list.map(l => 
        <MenuItem
          key={l}
          value={l}
        >
          {labelCallback && labelCallback(l) || l}
        </MenuItem>
      )}
    </Select> 
  </FormControl>
  : labelCallback && labelCallback(value) || value
}
</TableCell>

SelectTableCell.defaultProps = {
  labelCallback: undefined
}

const TextTableCell = ({ isEditing, value, onChange, labelCallback, dataTest }: {
  isEditing: boolean,
  value: string,
  onChange: any,
  dataTest: string,
  labelCallback?: any,
}): JSX.Element =>
  <TableCell data-test={dataTest} align="right">
    { isEditing ?
      <TextField size="small" defaultValue={value} onChange={onChange} /> :
      labelCallback && labelCallback(value) || value
    }
  </TableCell>

TextTableCell.defaultProps = {
  labelCallback: undefined
}

function Row(props: { 
  order: OrderProps,
  changeOrders: Dispatch<SetStateAction<OrderProps[]>>,
  busyEditing: boolean, 
  toggleBusyEditing: Dispatch<SetStateAction<boolean>>,
  setNotification: React.Dispatch<React.SetStateAction<{
    open: boolean
    message: string
    severity: string
    hideAfterXSeconds: number
  }>>,
  setSpinner: React.Dispatch<React.SetStateAction<{
    visible: boolean,
    text: string
  }>>
}): JSX.Element {

  const { 
    order,
    order: { 
      endDate: savedEndDate, 
      reminderDate: savedReminderDate, 
      company: savedCompany,
      client: savedClient
    },
    changeOrders, 
    busyEditing, 
    toggleBusyEditing,
    setNotification,
    setSpinner
  } = props;

  const { editOrder, generateReport, downloadReport, sendEmail } = api();

  const [ isEditing, toggleIsEditing ] = useState(false);

  const [ open, setOpen ] = useState(false);
  const [ endDate, setEndDate ] = useState(savedEndDate);
  const [ reminderDate, setReminderDate ] = useState(savedReminderDate);
  const [ company, setCompany ] = useState(savedCompany);
  const [ client, setClient ] = useState(savedClient);
  const [ save, doSave] = useState(false);

  const mountedRef = useRef(true);
  let timeoutId: ReturnType<typeof setTimeout>;

  const handleDownloadEditOrSave = () : void => {
    // download:
    if (order.ended && mountedRef.current) {
      if (!order.started) {
        alert(`${order.client.name} startade aldrig analysen, så inga svar har kommit in och ingen rapport finns att ladda ner.`);
        return;
      }
      if (order.respondents.filter(respondent => respondent.handedIn).length === 0 && !order.client.handedIn) {
        alert(`Ingen har besvarat analysen, så ingen rapport finns att ladda ner.`);
        return;
      }
      setSpinner({ visible: true, text: 'Genererar rapport...' })
      generateReport(order)
      .then(report => {
        if (mountedRef.current) {
          setSpinner({ visible: true, text: 'Laddar ner...' })
          downloadReport(report, order.client.name)
          .catch(error => {
            console.log('error dowloading report')
            console.log(error)
            if (mountedRef.current) {
            // if (isMounted) {
              setNotification({ 
                open: true, 
                message: 'FEL! Kunde ej ladda ner rapporten', 
                severity: 'error', 
                hideAfterXSeconds: 30
              })  
            }
          })
        }
      })
      .catch(error => {
        console.log('error generating report')
        console.log(error)
        if (mountedRef.current) {
        // if (isMounted) {
          setNotification({ 
            open: true, 
            message: 'FEL! Kunde ej generera rapporten', 
            severity:'error', 
            hideAfterXSeconds: 30
          })
        }
      })
      .finally(() => {
        if (mountedRef.current) { 
        // if (isMounted) { 
          setSpinner({ visible: false, text: '' }) 
        }
      })
    }
    // edit:
    else if (!busyEditing && mountedRef.current) { 
    // else if (!busyEditing && isMounted) { 
      toggleIsEditing(true);
      toggleBusyEditing(true);
    }
    // save:
    // else if (isEditing && mountedRef.current) {
    else if (isEditing) {
      doSave(true)
    }
  }

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
      clearTimeout(timeoutId);
    };
  }, []);

  // useEffect(() => () => { clearTimeout(timeoutId) }, []);

  useEffect(() => {
    if (save) {
      editOrder({ 
        oldOrder: order,
        newOrder: {
          client, company, reminderDate: reminderDate || null, endDate
        } as OrderProps
      }).then((savedOrder: OrderProps) => {
        const notificationMessage = { message: 'Sparat!', severity: 'success', hideAfterXSeconds: 3 };
        if (savedOrder.endDate !== order.endDate) {
          notificationMessage.message = "Sista svarsdatum uppdaterat - mail har gått ut till de som ännu inte har svarat";
          notificationMessage.hideAfterXSeconds = 15;
        }
        if (mountedRef.current) {
        // if (isMounted) {
          changeOrders( prev => {
            const changed = [...prev];
            const thisOrder = changed.filter( o => o.id === order.id)[0];
            const { client: thisClient } = thisOrder;
            thisClient.email = savedOrder.client.email;
            thisClient.name = savedOrder.client.name;
            thisClient.gender = savedOrder.client.gender;
            thisClient.language = savedOrder.client.language;
            thisOrder.company = savedOrder.company;
            thisOrder.endDate = savedOrder.endDate;
            thisOrder.reminderDate = savedOrder.reminderDate;
            thisOrder.id = savedOrder.id;
            thisOrder.started = savedOrder.started;
            thisOrder.ended = savedOrder.ended;
            thisOrder.languageOptions = savedOrder.languageOptions;   
            return changed;
          })
          setNotification({ open: true, ...notificationMessage })
        }
      }).finally(() => {
        if (mountedRef.current) {
        // if (isMounted) {
          toggleIsEditing(false);
          timeoutId = setTimeout(() => {
            // if (mountedRef.current) {
              toggleBusyEditing(false);    
            // }
          }, 2000);
        }
      });
      doSave(false);
    }
  }, [save])


  const displayReminderDateOrNothing = (): string =>
    savedReminderDate === "" ? "Ingen påminnelse" : savedReminderDate;

  // const respondentsRegistered = (): boolean => order.respondents && order.respondents.map(r => !r.handedIn).length > 0
  const respondentsRegistered = (): boolean => order.respondents && order.respondents.filter(r => !r.handedIn).length > 0

  const getActionHelperText = (): string => {    
    if (order.ended) {
      const days = daysLeftBeforeRemoval()
      return days > 0 ? `Ladda ner rapport (försvinner om ${days} dagar)` : `Ladda ner rapport`
    }
    if (isEditing) return "Spara"
    return "Ändra"
  }

  const daysLeftBeforeRemoval = (): number => {
    const end = new Date(Number.parseInt(`${order.endDate}`, 10));
    const date = new Date();
    const timeDiff = date.getTime() - end.getTime()
    const daysVisible = 14;
    return timeDiff > 0 ? daysVisible - Math.floor(timeDiff / ( 24 * 3600 * 1000)) : -1
  }

  const getActionIcon = (): JSX.Element => {

    if (order.ended) {
      return <FontAwesomeIcon icon="download" data-test="download-button"/>
    }

    if (isEditing) {
      return <FontAwesomeIcon icon="save" data-test="save-button"/>
    }

    return <FontAwesomeIcon icon="wrench" data-test="edit-button"/>
  }
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell>
          {respondentsRegistered() ?
            <IconButton
              aria-label="expand-row"
              size="small"
              data-test="expand-button"
              onClick={() => {
                setOpen(!open)
                if (open) {
                  toggleIsEditing(false)
                  toggleBusyEditing(false)
                  }
              }}
            >
              <FontAwesomeIcon icon={open ? "angle-up" : "angle-down"} />
            </IconButton> :
            <div />       
          }
        </TableCell>

        <SelectTableCell
          value={client.gender}
          onChange={ event => setClient(prev => ({ ...prev, gender: event.target.value as Gender }))}
          list={[ 'female', 'male', 'other' ]}
          labelCallback={(gender: string) => getGenderIcon(gender)}
          isEditing={isEditing}
          dataTest="gender-input"
        />
        <TextTableCell
          isEditing={isEditing}
          // isEditing={isEditing && !savedClient.handedIn}
          onChange={ event => setClient(prev => ({ ...prev, name: event.target.value }))}
          value={savedClient.name}
          dataTest="name-field"
        />
        <TextTableCell
          isEditing={isEditing && !order.started}
          onChange={ event => setClient(prev => ({ ...prev, email: event.target.value }))}
          value={savedClient.email}
          dataTest="email-field"
        />
        <TextTableCell
          isEditing={isEditing}
          onChange={ event => setCompany(event.target.value) }
          value={savedCompany}
          dataTest="company-field"
        />
        <TableCell data-test="progress-field" align="right">
          {Progress(order)}
        </TableCell>

        <TableCell data-test="reminder-field" align="right">
            {isEditing ? 
            <DateField
              minDate={ tomorrow }
              maxDate={ new Date(endDate) }
              value={ reminderDate }
              onChange={ (value: Date) => {
                if (value > today && value < new Date(endDate)) {
                  setReminderDate(dateFormat(value))
                }
                else if (value === null) {
                  setReminderDate("")
                }
              }}
              dataTest='reminder-date-picker'
              disabled={ new Date(order.reminderDate).getTime() < new Date().getTime() }
            />
            : displayReminderDateOrNothing()
            }
        </TableCell>

        <TableCell data-test="end-field" align="right">
          {isEditing ? 
          <DateField
            minDate={ new Date(endDate) }
            value={ endDate }
            onChange={ (event: Date) => {
              if (event > new Date(endDate)) {
                  setEndDate(dateFormat(event)) 
              }
            }}
            dataTest='end-date-picker'
            // helperText="Vid ändrat datum går automatiskt ett mail iväg till de som är kvar att svara"
          />
          : dateFormat(savedEndDate)
          }
        </TableCell>
        <SelectTableCell
          value={client.language || 'swedish'}
          isEditing={isEditing}
          onChange={ event => setClient(prev => ({ ...prev, language: event.target.value }))}
          list={order.languageOptions}
          labelCallback={languageString}
          dataTest="language-input"
        />
        <TableCell>
          <LoadingIconButton
            tooltip={order.ended ? `Inga fler mail kan skickas ut` : `Skicka om senast skickade mail till ${savedClient.name}`}
            dataTest="email-button"
            disabled={busyEditing || order.ended} 
            onClick={() => 
              savedClient.email && sendEmail('client', savedClient.email, order).then(failList => {
                if (failList.length === 0) {
                  setNotification({
                    open: true, 
                    message: "Skickade email!", 
                    severity: 'success', 
                    hideAfterXSeconds: 3
                  })
                }
                else {
                  setNotification({
                    open: true, 
                    message: `Kunde inte skicka email till ${failList[0]}!`, 
                    severity: 'error', 
                    hideAfterXSeconds: 3
                  })
                  // alert(`Kunde inte skicka email till ${failList[0]}!`)
                }
              })
            }
          >
            <FontAwesomeIcon icon="envelope" />
          </LoadingIconButton>
        </TableCell>
        <TableCell>
          <Tooltip title={ getActionHelperText() }>
            <span>
              <IconButton 
                disabled={!isEditing && busyEditing} 
                // onClick={ (event:React.MouseEvent<HTMLElement>) => handleDownloadEditOrSave(event) }
                onClick={() => handleDownloadEditOrSave()}
              >
                { getActionIcon() }              
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={5}
          style={{
            paddingBottom: 0,
             paddingTop: 0,
          }}
        >
          {respondentsRegistered() &&
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Respondenter kvar att svara:
              </Typography>
              <Table size="small" aria-label="Respondenter">
                <TableHead>
                  <TableRow>
                    <TableCell>Namn</TableCell>
                    <TableCell align="right">E-post</TableCell>
                    <TableCell align="right">Relation</TableCell>
                    <TableCell align="right">Språk</TableCell>
                    <TableCell />
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order.respondents && order.respondents.filter(r => !r.handedIn).map((r) => (
                    <RespondentRow 
                      key={r.email}
                      name={r.name} 
                      email={r.email} 
                      relation={r.relation} 
                      language={r.language || 'swedish'}
                      order={order}
                      changeOrders={changeOrders}
                      busyEditing={busyEditing} 
                      toggleBusyEditing={toggleBusyEditing}
                      setNotification={setNotification}
                      disabled={order.ended}
                      // isMounted={isMounted}
                    />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
          }
        </TableCell>
      </TableRow>
    </>
  );
}

StatusPage.defaultProps = {
  fromLogin: false,
  fromOrder: false
}

function StatusPage({fromLogin, fromOrder}: {fromLogin?: boolean, fromOrder?: boolean}): JSX.Element {
  const { user } = useUser();

  const [busyEditing, toggleBusyEditing] = useState(false);
  const [orders, changeOrders] = useState([] as OrderProps[]);
  const [ notification, setNotification] = useState(fromOrder ? 
    { open: true, message: "Tack! Beställningen är lagd. Mail har skickats till klienten.", severity: "success", hideAfterXSeconds: 15 } : 
    { open: false, message: "Skickade email!", severity: "success", hideAfterXSeconds: 3 });
  const [ spinner, setSpinner ] = useState({ visible: false, text: 'Genererar rapport...' });

  const mountedRef = useRef(true);

  const initializePage = async () : Promise<void> => 
    api().getOrders().then( savedOrders => {
      if (fromLogin && savedOrders.length === 0) {
        browserHistory.replace( '/order', { license: user.licenses[0] });
      }
      if (mountedRef.current) {
          changeOrders(savedOrders as unknown as OrderProps[]);
      }
    })
    .catch(error => {
      console.log(`Error getting orders, ${error}`);
      console.log('details:');
      console.log({error});
    });

  // let mounted = true;

  useEffect(() => {
    mountedRef.current = true;
    initializePage();
    return () => { mountedRef.current = false }
  }, []);

  return (
    <>
      {spinner.visible && <Spinner text={spinner.text} />}
      {orders && orders.length > 0 ? (
        <Grid container component="main" width="100%" mt="10vh">
          <TableContainer>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {/* <TableCell align="center">Kvinna / Man</TableCell> */}
                  <TableCell />
                  <TableCell align="center">Klient</TableCell>
                  <TableCell align="center">E-post</TableCell>
                  <TableCell align="center">Företag</TableCell>
                  <TableCell align="center">Inkomna svar</TableCell>
                  <TableCell align="right">Påminnelse</TableCell>
                  <TableCell align="right">Sista svarsdatum</TableCell>
                  <TableCell align="center">Språk</TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                  { orders.map( order => (
                    <Row 
                      key={order.id} 
                      order={order} 
                      changeOrders={changeOrders} 
                      busyEditing={busyEditing} 
                      toggleBusyEditing={toggleBusyEditing}
                      setNotification={setNotification}
                      setSpinner={setSpinner}
                      // isMounted={mountedRef.current}
                    />
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid container component="main" width="100%" mt="10vh">
          <Typography>
            Inga pågående analyser - ingen status att visa
          </Typography>
        </Grid>
      )}
      <SuccessNotification
        open={notification.open}
        handleClose={() => setNotification({
          open: !notification.open, 
          message: notification.message, 
          severity: notification.severity,
          hideAfterXSeconds: notification.hideAfterXSeconds
        })}
        message={notification.message}
        severity={notification.severity as AlertColor}
      />
    </>
  );
}

export { StatusPage };
