import axios from "axios";

// const api = process.env.NODE_ENV === 'development' ? 'http://localhost:7001' : 'https://api.sleipner.nu';
const api = process.env.REACT_APP_API_URL;
if (api === undefined) {
    throw new Error('url to api is not set at fetcher');
}

export const fetcher = async (args: any): Promise<any> =>
    axios.post(
        `${api}/graphql`,
        { ...args },
        { headers: { "Content-Type": "application/json" } }
    )
    .then( ({ data }) => data.data.user )
    .catch((error: any) => {
        if (error.response.status === 309) {
            return null
        }
        const e = { ...error } as any;
        e.status = 403;
        throw e;
    });

export const userLogout = async (): Promise<boolean> =>
    axios.post(`${api}/logout`).then(() => true)
