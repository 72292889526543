import { FormEvent, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertColor, FormControl, IconButton, Input, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material";
import { SuccessNotification } from "../_components/SuccessNotification";
import { Email } from "../_interfaces/EmailInterface";
import { License } from "../_interfaces/OrderInterface";
import { User } from "../_interfaces/UserInterface";
import { api } from "../_services/apiServices";
import { LoadingFormButton } from "../_components/LoadingFormButton";

function PortalPage(): JSX.Element {

  const { getLicenses, newUser, newLicense, newEmail } = api();
  
  const concernings = ['coach', 'client', 'respondent', 'admin', 'support'];

  const [license, updateLicense] = useState({ label:'', languages:[], price:590, type:'360', key:'', pcs:0, discountPrice: 590*.25, discountLimit: 20 } as License);
  const [licenses, refreshLicenses] = useState([] as License[]);
  const [activeLicense, setActiveLicense] = useState({ label: '' } as License);
  const [concerning, updateConcerning] = useState(concernings[0]);
  const [email, updateEmail] = useState({ description: '', concerning } as Email);
  const [user, updateUser] = useState({ name: '', email: '' } as User);
  const [notification, toggleNotification] = useState<{open: boolean, message: string, severity: AlertColor}>
    ({ open: false, message: 'Sparat!', severity:'success' });
  const [pic, setPic] = useState('');

  useEffect(() => {
    getLicenses()
    .then((data:License[]) => {
      refreshLicenses(data);
      if (data){
        if (data.length > 0 && activeLicense.label === '') {
          setActiveLicense(data[0])
        }
        const licenseWithLogo = data.filter(d => d.logo !== null);
        if (licenseWithLogo && licenseWithLogo.length > 0) {
          const l = licenseWithLogo[0].logo as unknown as string;
          setPic(l);
        }  
      }
    })
    .catch((error: unknown) => {
      console.error('Could not get licenses.');
      console.error(error);
    })
  }, [licenses && licenses.length]);

  const handleLicenseSubmit = async (): Promise<boolean> => 
    newLicense(license).then(l => {
      refreshLicenses(prev => ([...prev, l])) // <-- forces a refresh and a fetch from the server, so this should be safe
      toggleNotification(previousNotification => {
        const changes = { ...previousNotification };
        changes.message = 'Sparade ny licens!';
        changes.open = true;
        changes.severity = 'success'
        return changes;
      });
      return true;
    }).catch(error => {
      console.error(`Could not save new license: ${error}`)
      return false;
    });

  const handleEmailSubmit = async (event: FormEvent<HTMLFormElement>): Promise<boolean> => {
    event.preventDefault();
    email.concerning = concerning;
    email.subject = '';
    email.body = '';
    return newEmail(email, activeLicense).then(() => {
      updateEmail({ description: '', concerning } as Email)
      toggleNotification(previousNotification => {
        const changes = { ...previousNotification };
        changes.message = 'Sparade ny epost';
        changes.open = true;
        changes.severity = 'success'
        return changes;
      });
      return true;
    }).catch(error => {
      console.error(`Could not save email: ${error}`) // catches: 400 (bad request), 500 (internal server error), 403(forbidden) 
      return false;
    });
  }

  const handleUserSubmit = async (event: FormEvent<HTMLFormElement>): Promise<boolean> => {
    event.preventDefault();
    const { logo: _logo, ...singleUserLicense } = activeLicense;
    user.licenses = [ singleUserLicense ];
    user.roles = ['coach']; // this is later ignored.
    return newUser(user).then(u => {
      if (u === null) {
        toggleNotification(previousNotification => {
          const changes = { ...previousNotification };
          changes.message = 'Kunde ej spara ny användare';
          changes.open = true;
          changes.severity = 'error'
          return changes;
        });
        return false;
      }
      updateUser({ name: '', email: '' } as User);
      toggleNotification(previousNotification => {
        const changes = { ...previousNotification };
        changes.message = 'Sparade ny användare!';
        changes.open = true;
        changes.severity = 'success'
        return changes;
      });
      return true;
    }).catch(error => {
      console.error(`Could not save user: ${error}`)
      return false;
    });
  };

  const handleCloseNotification = (event: React.SyntheticEvent | Event, reason?: string): void => {
    if (reason === 'clickaway') {
      toggleNotification({ message: notification.message, open: false, severity: 'success' });
      return;
    }
    toggleNotification({ message: notification.message, open: false, severity: 'success' });
  };

  return (
    <>
    <Typography variant='h5' mt='10vh' mb='2vh' align='center'>Hantera portalen</Typography>
    {pic !== '' ?
      <img
        src={`data:image/png;base64,${pic}`}
        style={{
          width: 100,
          height: 40
        }}  
        alt='logo'
      /> :
      <Skeleton variant="rectangular" width={100} height={40} />
    }
    <hr style={{ marginTop:'4vh', marginBottom:'2vh' }}/>
    <Typography variant='h6' mt='2vh' mb='2vh' align='center'>Licenser</Typography>
    <TextField 
        id='licenseName'
        name='licenseName'
        label='Namn på licensen'
        value={license.label}
        onChange={(event) => {
          updateLicense( previousLicense => {
            const changes = { ...previousLicense };
            changes.label = event.target.value;
            return changes;
        })}} 
        fullWidth
        margin="normal"
      />
    <TextField 
        id='licensePrice'
        name='licensePrice'
        label='Pris per beställning ex moms'
        value={license.price}
        onChange={(event) => {
          updateLicense( previousLicense => {
            const changes = { ...previousLicense };
            changes.price = Number.parseInt(event.target.value, 10);
            return changes;
        })}}
        fullWidth
        margin="normal"
      />
    <label htmlFor="uploadLogo" aria-label="upload logo">
      Logga: 
      <Input 
        type="file" 
        id="uploadLogo" 
        name="uploadLogo" 
        aria-label="upload logo" 
        onChange={ (event: any) => {
          const [ file ]: File[] = event.target.files;
          updateLicense( previousLicense => {
            const changes = { ...previousLicense };
            changes.logo = file;
            return changes;
          })
        }}/>
      <IconButton color="primary" component="span">
        <FontAwesomeIcon icon="image" />
      </IconButton>
    </label>
    <TextField 
        id='licenseLanguage'
        name='licenseLanguage'
        label='Språk, listade med kommatecken emellan: "swedish,english"'
        value={license.languages}
        onChange={(event) => {
          updateLicense( previousLicense => {
            const changes = { ...previousLicense };
            changes.languages = event.target.value.split(',').map(l => l.trim());
            return changes;
        })}} 
        fullWidth
        margin="normal"
    />
    <LoadingFormButton
      sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
      onClick={ handleLicenseSubmit }
    >
      Spara licens
    </LoadingFormButton>

    <hr style={{ marginTop:'4vh', marginBottom:'2vh' }}/>

    <Typography variant='h6' mt='2vh' mb='2vh' align='center'>
      Välj aktuell licens för ändringarna nedan
    </Typography>

    <FormControl fullWidth >
      <InputLabel id="licenseSelectLabel">Licens</InputLabel>
      <Select
        id="licenseSelect"
        name="licenseSelect"
        labelId="licenseSelectLabel"
        value={activeLicense.label}
        label="Licens"
        onChange={(event) => {
          setActiveLicense(licenses.filter(l => l.label === event.target.value)[0])
        }}
      >
        {
        licenses && licenses.map((l:License) => 
          <MenuItem 
          // string={l.key} 
          key={l.label}
          value={l.label}>{l.type}, {l.label}</MenuItem>)
        }
      </Select>
    </FormControl>

    <Typography variant='h6' mt='2vh' mb='2vh' align='center'>E-post</Typography>
    <FormControl fullWidth >
      <InputLabel id="concerningSelectLabel">Skickas till:</InputLabel>
      <Select
        id="concerningSelect"
        name="concerningSelect"
        labelId="concerningSelectLabel"
        value={concerning}
        label="Skickas till:"
        onChange={(event) =>
          updateConcerning(event.target.value)
        }
      >
        {
        concernings.map( c => 
          <MenuItem
          key={c} 
          value={c}>{c}</MenuItem>
        )}
      </Select>
    </FormControl>

    <TextField 
      id='emailDescription'
      name='emailDescription'
      label='Beskrivning av e-post-meddelandet, exempelvis "Client: Startmail"'
      value={email.description}
      onChange={(event) => {
        updateEmail( previous => {
          const changes = { ...previous };
          changes.description = event.target.value;
          return changes;
      })}} 
      fullWidth
      margin="normal"
    />

    <LoadingFormButton
      sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
      onClick={ handleEmailSubmit }
    >
      Spara e-post
    </LoadingFormButton>

    <Typography variant='h6' mt='2vh' mb='2vh' align='center'>Användare</Typography>
        <TextField 
          id='userName'
          name='userName'
          label='Användarens namn'
          value={user.name}
          onChange={(event) => {
            updateUser( previous => {
              const changes = { ...previous };
              changes.name = event.target.value;
              return changes;
          })}} 
          fullWidth
          margin="normal"
        />
        <TextField 
          id='userEmail'
          name='userEmail'
          label='Användarens e-post'
          value={user.email}
          onChange={(event) => {
            updateUser( previous => {
              const changes = { ...previous };
              changes.email = event.target.value;
              return changes;
          })}} 
          fullWidth
          margin="normal"
        />

        <LoadingFormButton 
          sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
          onClick={ handleUserSubmit }
        >
          Spara användare
        </LoadingFormButton>

        <SuccessNotification
          message={notification.message}
          open={notification.open}
          handleClose={handleCloseNotification}
          severity={notification.severity}
        />
    </>
  );
}

export { PortalPage };