import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
    Card, CircularProgress, FormControl, FormControlLabel, Grid, InputLabel, TextareaAutosize,
    MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Typography, Input,
    IconButton} from "@mui/material"
import { Box } from "@mui/system";
import { ChangeEvent, Dispatch, memo, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { LoadingFormButton } from "../_components/LoadingFormButton";
import { userHasRelation } from "../_helpers/AccessRoles";
import { useUser } from "../_hooks/useUser";
import { User } from "../_interfaces";
import { Part, Relation, Respondent } from "../_interfaces/OrderInterface";
import { Form } from "../_interfaces/QuestionInterface";
import { api } from "../_services/apiServices";
import strings from "../_strings/FormPage.json";
import relationStrings from "../_strings/Relations.json";
import { LanguageContext } from "../_contexts/LanguageContext";
import { SLink } from "../_components/SLink";
import browserHistory from "../_helpers/History";

const FormPage = ({ user, part }: { user: User, part: Part }): JSX.Element => {
    const { mutate } = useUser();
    const { getForm } = api();
    const { client, link } = part;

    const [form, setForm] = useState({} as Form);
    const [answerArray, changeAnswerArray] = useState([] as { id: string, value: string | null }[]);
    const [allSelected, setAllSelected] = useState(false);
    const [comment, setComment] = useState('');
    const [familiarity, setFamiliarity] = useState("well");
    const [file, setFile] = useState<File | undefined>(undefined);

    const { language } = useContext(LanguageContext);
    // TODO: this was called with getForms() before, possibly getting all forms?
    useEffect(() => {
        window.scrollTo(0, 0);
        getForm({ link })
            .then(f => setForm(f))
            .catch(e => alert(e));
    }, []);

    const onTextareaChanged = useCallback((event: ChangeEvent<HTMLTextAreaElement>): void => {
        setComment(event.target.value);
    }, [comment]);

    const handleFormSubmit = async (): Promise<boolean> => {
        const { started: startedRemovedFromPart, client: clientRemovedFromPart, coach: coachRemovedFromPart, ...order } = part;
        return api().sendAnswers({ familiarity, comment, answers: answerArray, order, file })
        .then(() => {
            mutate().then(() => {
                const hasPartsIndex = user.parts.findIndex(userPart => userPart.relation === Relation.self)
                if (hasPartsIndex !== -1) {
                    browserHistory.push('/analysis')
                }
            })
            return false;
        });
    }

    const maxFileSize = 10000000;
    const fileIsOk = file && file.size < maxFileSize && file.type === 'application/pdf';

    return form && form.sections ?
        <>
            <Card sx={{ marginTop: '10vh', padding:'6px' }}>
                <Typography>
                    <FontAwesomeIcon icon="circle-info"/>
                    { strings[language].info1 }
                    <i>HTTPS</i>
                    { strings[language].info2 }
                </Typography>
            </Card>
            <Typography mt='6vh'>
                { strings[language].howto }
                {part.relation === Relation.self ? strings[language].you : client.name}.
            </Typography>

            {part.relation !== Relation.self &&
                <MemoSelect gender={client.gender} setValue={setFamiliarity} value={familiarity} language={language} />
            }
            { user && userHasRelation(user as unknown as Respondent, 'superior') && 
                <Typography>
                    { strings[language].superiorText1 }
                    <q>{ relationStrings[language].superior }</q>
                    { strings[language].superiorText2 }
                </Typography>
            }
            <Typography mt='1vh'>
                { strings[language].faq1 }
                <SLink href="/faq">{ strings[language].faq }</SLink>
                { strings[language].faq2 }
            </Typography>

            <MemoFormQuestions
                form={form} 
                valueArray={answerArray} 
                setValueArray={changeAnswerArray} 
                setAllSelected={setAllSelected}
                language={language}
            />
            <hr style={{ marginTop:'4vh', marginBottom:'2vh' }}/>
            
            { part.relation !== Relation.self && 
                <>
                    <Typography mb='2vh' variant="h5" color='rgb(84, 150, 168)'>{ strings[language].moreFeedback1 }</Typography>
                    <MemoTextArea comment={comment} onChange={onTextareaChanged} maxLength={1000} />
                    <Typography marginBottom='2vh' marginTop='2vh'>
                        <FontAwesomeIcon icon='triangle-exclamation' color='red'/>
                        { strings[language].gdprTitle } <br/>
                        { strings[language].gdpr }
                    </Typography>
                    <label htmlFor="uploadComment" aria-label="upload comment">
                        <Typography marginBottom='2vh' marginTop='2vh' component='span'>
                            { strings[language].uploadComment }
                        </Typography>
                        <Input 
                            inputProps={{ accept: ".pdf", 'data-test': 'upload-comment', style: { display: 'none' } }}
                            type="file"
                            id="uploadComment" 
                            name="uploadComment" 
                            aria-label="upload comment" 
                            onChange={ (event: any) => setFile(event.target.files[0]) }
                            />
                        <IconButton color="primary" component="span">
                            <FontAwesomeIcon icon="file-arrow-up" color={(file && !fileIsOk) ? 'red' : 'undefined'}/>
                            {file && !fileIsOk && <Typography component='span' marginLeft='2vh' color='red'>{ strings[language].tooLarge }</Typography>}
                        </IconButton>
                        <Typography component='span' color='rgb(84, 150, 168)'>
                            {fileIsOk && <><Typography component='span'>{file.name}</Typography> <FontAwesomeIcon icon="check" color="green"/></> || strings[language].uploadSize }
                        </Typography>
                    </label>
                </>
            }
            <LoadingFormButton 
                dataTest="form-submit"
                disabled={!allSelected || (file !== undefined && !fileIsOk) }
                sx={{ padding:'1vh', marginTop:'16px', marginBottom:'8px' }}
                onClick={ handleFormSubmit }
            >
                { strings[language].send }
            </LoadingFormButton>
        </>
        : 
        <Box sx={{ display: 'flex', justifyContent:'center', height: '100vh', alignItems:'center' }}>
            <Typography variant='h5' mr='16px'>{ strings[language].fetchingQuestions }</Typography> <CircularProgress />
        </Box>
}

const MySelect = ({ value, setValue, gender, language }: {
    value: string, 
    setValue: Dispatch<SetStateAction<string>>, 
    gender: string,
    language: string
}): JSX.Element => {

    const henom = (): string => {
        if (gender === 'female')
            return strings[language].her;
        if (gender === 'male')
            return strings[language].him;
        return strings[language].thisPerson;
    }

    return (
        <Box mt="15px" mb="15px" sx={{ maxWidth: "300px" }}>
            <FormControl fullWidth >
                <InputLabel id="familiarityLabel">{ strings[language].howWell }{ henom() }?</InputLabel>
                <Select
                    id="familiarity"
                    name="familiarity"
                    labelId="familiarityLabel"
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    label={`${ strings[language].howWell }${ henom() }?`}
                >
                    <MenuItem value="very well">{ strings[language].veryWell }</MenuItem>
                    <MenuItem value="well">{ strings[language].well }</MenuItem>
                    <MenuItem value="not well">{ strings[language].notWell }</MenuItem>
                    <MenuItem value="not at all">{ strings[language].notAtAll }</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

const MemoSelect = memo(MySelect);

const MyTextArea = ({ comment, maxLength, onChange }: {
    comment: string, maxLength: number, onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
}): JSX.Element =>
    <TextareaAutosize
        id='comment'
        name='comment'
        minRows={10}
        style={{ width:'100%' }}
        value={comment}
        onChange={onChange}
        maxLength={maxLength}
    />

const MemoTextArea = memo(MyTextArea);

const MyFormQuestions = ({ form, valueArray, setValueArray, setAllSelected, language }: {
    form: Form,
    valueArray: { id: string, value: string | null }[],
    setValueArray: Dispatch<SetStateAction<{ id: string, value: string | null }[]>>,
    setAllSelected: Dispatch<SetStateAction<boolean>>,
    language: string
}): JSX.Element => {
    
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ';
    useEffect(() => {
        const nullValuesFound = valueArray.some(answer => answer.value === null);
        setAllSelected(!nullValuesFound);
    }, [valueArray]);

    const onChoiceSelected = useCallback((event: 
        ChangeEvent<HTMLInputElement> | 
        SelectChangeEvent<string | null>
        ): void => {
        setValueArray(prev => {
            const changed = [...prev];
            const index = changed.findIndex(question => question.id === event.target.name);
            if (index !== -1) {
                changed[index].value = event.target.value
            }
            return changed;
        })
    }, [valueArray]);

    useEffect(() =>{
        const initialAnswerArray: { id: string, value: string|null }[] = [];
        if (form.sections && valueArray.length === 0) {
            form.sections.forEach(s =>
                s.groups.forEach(g =>
                    g.questions.forEach(q =>
                        initialAnswerArray.push({ id: q.id, value: null })
                    )
                )
            );
            setValueArray(initialAnswerArray);
        }
    }, [form]);

    return form && form.sections ? (
        <>
            {form.sections.map(section =>
                section.groups.map(group =>
                    <Grid 
                    container 
                    data-test="group" 
                    key={group.id}>
                        <Grid item component="hr" xs={12} marginTop='4vh' marginBottom='2vh'/>
                        <Grid item xs={12} sm={7}>
                            <Typography variant="h5" color='rgb(84, 150, 168)' data-test="label">
                                {`${alphabet[group.index]}. ${JSON.parse(group.text)[language]}`}
                            </Typography>
                        </Grid>
                        <Grid item component={Box} display={{ xs:"none", md:"flex" }} sm={4} >
                            <Typography style={{ marginRight:'1px', width:'20%', textAlign:'center' }} fontSize="small">{ strings[language].notWell }</Typography>
                            <Typography style={{ marginRight:'1px', width:'20%', textAlign:'center' }} fontSize="small">{ strings[language].prettyWell }</Typography>
                            <Typography style={{ marginRight:'1px', width:'20%', textAlign:'center' }} fontSize="small">{ strings[language].well }</Typography>
                            <Typography style={{ marginRight:'1px', width:'20%', textAlign:'center' }} fontSize="small">{ strings[language].veryWell }</Typography>
                            <Typography style={{ width:'20%', textAlign:'center' }} fontSize="small">{ strings[language].dontKnow }</Typography>
                        </Grid>
                        {group.questions.map(question =>
                            <Grid 
                            container 
                            alignItems='center' 
                            data-test="question" 
                            key={question.id}>
                                <Grid item xs={12} md={7} >
                                    <Typography variant='h6' data-test="label">
                                        {`${question.index + 1}. ${JSON.parse(question.text)[language]}`}
                                    </Typography>    
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <RadioGroup 
                                        row 
                                        style={{ width:'100%' }} 
                                        id={`question${question.id}`} 
                                        name={`${question.id}`}
                                        value={valueArray.find(v => v.id === question.id)?.value || '0'}
                                        onChange={onChoiceSelected}
                                    >
                                        {/* visible on larger screens: */}
                                        <Grid 
                                            component={Box} 
                                            display={{ xs:"none", md:"flex", justifyContent:'space-around', width:'100%' }}
                                        >
                                            <Radio value={1} data-test="1" />
                                            <Radio value={2} data-test="2" />
                                            <Radio value={3} data-test="3" />
                                            <Radio value={4} data-test="4" />
                                            <Radio value={-1} data-test="-1" style={{ backgroundColor:'rgba(0, 0, 0, 0.05)' }}/>
                                        </Grid>
                                        {/* visible on smaller screens: */}
                                        <Grid 
                                            component={Box} 
                                            display={{ xs:"flex", md:"none", width:'100%', justifyContent:'space-around' }}
                                        >
                                            <FormControlLabel 
                                                value="1" 
                                                labelPlacement="top" 
                                                control={<Radio />} 
                                                label={<Typography align='center'>{ strings[language].notWell }</Typography>}
                                                style={{ margin:'2px' }}
                                            />
                                            <FormControlLabel 
                                                value="2" 
                                                labelPlacement="top" 
                                                control={<Radio />} 
                                                label={<Typography align='center'>{ strings[language].prettyWell }</Typography>}
                                                style={{ margin:'2px' }} 
                                            />
                                            <FormControlLabel 
                                                value="3" 
                                                labelPlacement="top" 
                                                control={<Radio />} 
                                                label={<Typography align='center'>{ strings[language].well }</Typography>}
                                                style={{ margin:'2px' }} 
                                            />
                                            <FormControlLabel 
                                                value="4" 
                                                labelPlacement="top" 
                                                control={<Radio />} 
                                                label={<Typography align='center'>{ strings[language].veryWell }</Typography>}
                                                style={{ margin:'2px' }} 
                                            />
                                            <FormControlLabel 
                                                value="-1" 
                                                labelPlacement="top" 
                                                control={<Radio style={{ backgroundColor:'rgba(0, 0, 0, 0.05)' }} />} 
                                                label={<Typography align='center'>{ strings[language].dontKnow }</Typography>}
                                                style={{ margin:'2px' }} 
                                            />
                                        </Grid>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                )    
            )
        }
    </>) : <div />
}

const MemoFormQuestions = memo(MyFormQuestions);

export { FormPage }